// Add your own custom CSS here.
@import "pygments-tomorrow";

.post-content img,
.post-excerpt-home img,
.post-nav img {
  @extend .img-rounded;
}
.post-content table,
.post-excerpt-home table,
.post-nav table {
  @extend .table;
  @extend .table-bordered;
}

/**
 * Navigation
 */
ul.nav li.dropdown:hover ul.dropdown-menu {
  display: block;
}

/**
 * Sidebar
 */
.post-title-reference {
  list-style: none;
  margin-left: 0;
}

/**
 * Site footer
 */
$spacing-unit:     30px !default;

.site-footer {
  border-top: 1px solid $gray-lighter;
  padding: $spacing-unit 0;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  font-size: 15px;
  color: $gray-light;
  //margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  //padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  width: -webkit-calc(35% - (#{$spacing-unit} / 2));
  width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(20% - (#{$spacing-unit} / 2));
  width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}
/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}
